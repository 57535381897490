/** @format */

@import 'styles/variables.scss';

.App {
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;

  .top-bar {
    background: white;
    width: 100%;
    height: $topBarHeight;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .logo {
    z-index: 2;
    max-width: 200px;
    height: 41px;
    margin: 10px 0px -51px 5px;
    object-fit: contain;
    object-position: top;
  }
  .logo-extra-margin {
    margin: 20px 0px -61px 20px;
  }
  .footer {
    z-index: 2;
    max-width: 100px;
    height: 8px;
    margin: -8px 0px 0px 5px;
    font-size: 6px;
    @extend %light;
  }
}
