/** @format */

@import 'styles/variables.scss';

.scLoading {
  position: absolute;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :global {
    $loading-full-size: 52px;
    $dot-space: 20px;
    $dot-size: ($loading-full-size - $dot-space) / 2;

    .loading {
      background-color: transparent;
      width: $loading-full-size;
      height: $loading-full-size;
      position: relative;
    }

    .dot {
      position: absolute;
      background-color: gray;
      opacity: 0.5;
      width: $dot-size;
      height: $dot-size;
      border-radius: $dot-size / 2;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }
  }
}
