/** @format */

@keyframes playTone {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes scale-it {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@-webkit-keyframes scale-it {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

// Instruction
@keyframes instructionDismiss {
  0% {
    margin-bottom: 0px;
  }
  100% {
    margin-bottom: calc(var(--app-height) * -1);
  }
}
@keyframes splashDisplay {
  0% {
    margin-top: calc(var(--app-height) * -1);
  }
  100% {
    margin-top: 0px;
  }
}

// Loading
$loading-range: 36px;
@keyframes loadingTopLeft {
  0% {
    top: 0px;
    left: 0px;
  }
  100% {
    top: 0px;
    left: $loading-range;
  }
}
@keyframes loadingTopRight {
  0% {
    top: 0px;
    right: 0px;
  }
  100% {
    top: $loading-range;
    right: 0px;
  }
}
@keyframes loadingBottomRight {
  0% {
    bottom: 0px;
    right: 0px;
  }
  100% {
    bottom: 0px;
    right: $loading-range;
  }
}
@keyframes loadingBottomLeft {
  0% {
    bottom: 0px;
    left: 0px;
  }
  100% {
    bottom: $loading-range;
    left: 0px;
  }
}
