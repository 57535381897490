/** @format */

@font-face {
  font-family: 'Apercu';
  src: url('~static/fonts/Apercu-Regular.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Apercu-Medium';
  src: url('~static/fonts/Apercu-Medium.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Apercu-Bold';
  src: url('~static/fonts/Apercu-Bold.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Apercu-Light';
  src: url('~static/fonts/Apercu-Light.otf') format('opentype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Apercu', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // disable selection
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  // disable bounce
  position: fixed;
  // disable chrome pull to refresh on iOS but only works on Android
  // https://developers.google.com/web/updates/2017/11/overscroll-behavior
  overscroll-behavior-y: contain;

  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
