/** @format */

// const
$mobile-max-width: 700px;

// fonts
%regular {
  font-family: 'Apercu', sans-serif;
}
%medium {
  font-family: 'Apercu-Medium', sans-serif;
}
%bold {
  font-family: 'Apercu-Bold', sans-serif;
}
%light {
  font-family: 'Apercu-Light', sans-serif;
}

// colors
$colorBlue: #50c0e5;
$colorGreenLight: #41b4b4;
$colorGreenDark: #338782;
$colorOrange: #e7812e;
$colorBackground: #fbeadd;
$colorDisabled: #d3d3d3;
$colorText: #635f76;

$colorErrorOnWhiteBackground: #e7812e;

// styles
@mixin base-frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin adapt-width($maxPercent: 80%) {
  width: $maxPercent;
  max-width: $mobile-max-width;
}

@mixin circle-button($backColor, $width: 100px, $fontSize: 23px) {
  background-color: $backColor;
  width: $width;
  height: $width;
  border-radius: $width / 2;
  font-size: 23px;
  text-align: center;
  line-height: $width;
  color: white;
}

@mixin div-back-img($img) {
  background-image: url('~static/images/' + $img + '.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

// dimensions
$topBarHeight: 35px;
