/** @format */

@import 'styles/variables.scss';

.wrap {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 9;

  ul {
    list-style: none;
    background: white;
    box-shadow: 1px 1px 2px;
    padding: 2px 8px;
    margin-top: 5px;
    border-radius: 5px;
  }

  li {
    margin: 2px 0;
  }
}

button {
  border: none;
  background: none;
  cursor: pointer;

  font-size: 1.3rem;
}

.right {
  text-align: right;
}

.icon {
  height: 1.5rem;
}

.selected {
  border: 2px solid $colorBlue;
}

.hidden {
  display: none;
}
